import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';
import { Enterprise } from 'pages/apps/Enterprise/types';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else {
            switch (error.response.status) {
                case 401:
                    message = '无效的服务器会话凭据，请稍后重试！';
                    break;
                case 403:
                    message = '禁止访问!';
                    break;
                case 404:
                    message = '很抱歉找不到您要查找的数据！';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
    const { user } = getSession()
    return user;
};

export const getTokenFromSession = () => {
    const { token } = getSession()
    return token;
};

const getSession = () => {
    const session = storage().getItem(AUTH_SESSION_KEY);
    return (typeof session == 'object' ? session : JSON.parse(session)) || {};
}

const getUserRole = (): string => {
    const { user } = getSession()

    return user?.role || "Guest";
};

const getUserEnterprise = (): Enterprise => {
    const { user } = getSession()

    return user?.selectEnterprise;
};

const storage = () => {
    return localStorage
}

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url: string, params?: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = (url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: any) => {
        return axios.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url: string) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    createWithFiles = (url: string, key: string, files: File[]) => {
        const formData = new FormData();
        for (const k of files) {
            formData.append(key, k)
        }
        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            }
        }
        return axios.post(url, formData, config);
    }

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const session = getSession();
        const { user, token } = session;

        if (!user || (user && !token)) {
            return false;
        }
        const decoded: any = jwtDecode(token);
        const currentTime = (new Date()).getTime() / 1000;
        if (decoded.exp < currentTime) {
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session: any) => {
        if (session) storage().setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            storage().removeItem(AUTH_SESSION_KEY);
        }
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    getLoggedRole = () => {
        return getUserRole();
    };

    getEnterprise = () => {
        return getUserEnterprise()
    }

    setUserInSession = (modifiedUser: any) => {
        let userInfo = storage().getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, user: { ...user, ...modifiedUser } });
        }
    };
}

/*
Check if token available in session
*/
let token = getTokenFromSession();
if (token) {
    setAuthorization(token);
}

export { APICore, setAuthorization };
