import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listCustomerAcquisitions as listCustomerAcquisitionsApi
} from 'helpers';
import { customerAcquisitionApiResponseSuccess, customerAcquisitionApiResponseError, CustomerAcquisition } from './actions';
import { CustomerAcquisitionsActionTypes } from './constants';

type CustomerAcquisitionPayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: CustomerAcquisition[] | CustomerAcquisition | {};
    page?: number,
    pageSize?: number,
    corp_id: string
    limit: number
  }
  type: string
}

function* listCustomerAcquisitions({ payload: { corp_id }, type }: CustomerAcquisitionPayload): SagaIterator {
  try {
    const response = yield call(listCustomerAcquisitionsApi, corp_id);
    const { customerAcquisitions } = response;
    yield put(customerAcquisitionApiResponseSuccess(CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS, customerAcquisitions))
  } catch (error: any) {
    yield put(customerAcquisitionApiResponseError(CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS, error));
  }
}

function* watchListCustomerAcquisitions() {
  yield takeEvery(CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS, listCustomerAcquisitions);
}

function* customerAcquisitionsSaga() {
  yield all([fork(watchListCustomerAcquisitions)])
}

export default customerAcquisitionsSaga;