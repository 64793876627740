export enum FormsActionTypes {
  API_RESPONSE_SUCCESS = '@@forms/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@forms/API_RESPONSE_ERROR',

  LIST_FORM = '@@forms/LIST_FORM',
  CREATE_FORM = '@@forms/CREATE_FORM',
  UPDATE_FORM = '@@forms/UPDATE_FORM',
  GET_FORM = '@@forms/GET_FORM',
  DELETE_FORM = '@@forms/DELETE_FORM',

  RESET = '@@forms/RESET',
}