import { Page } from 'redux/common';
import { Customer } from './actions';
import { CustomerActionTypes } from './constants';

const INIT_STATE = {
  customers: [],
  loading: false,
};

type CustomerActionType = {
  type:
  | CustomerActionTypes.API_RESPONSE_SUCCESS
  | CustomerActionTypes.API_RESPONSE_ERROR
  | CustomerActionTypes.LIST_CUSTOMERS
  | CustomerActionTypes.CREATE_CUSTOMER
  | CustomerActionTypes.UPDATE_CUSTOMER
  | CustomerActionTypes.DELETE_CUSTOMER
  | CustomerActionTypes.APPEND_CUSTOMER
  | CustomerActionTypes.REMOVE_CUSTOMER
  | CustomerActionTypes.GET_CUSTOMER
  | CustomerActionTypes.RESET

  payload: {
    actionType?: string;
    data?: Customer[] | Customer | {};
    error?: string;
    id?: number;
    page: Page;
  }
}

type State = {
  customers?: Customer[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: Customer[], customerId: number): number => {
  let idx = data.findIndex((a) => a.id === customerId);
  return idx < 0 ? data.length : idx;
}

const Customers = (state: State = INIT_STATE, action: CustomerActionType) => {
  switch (action.type) {
    case CustomerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CustomerActionTypes.LIST_CUSTOMERS: {
          return {
            ...state,
            customers: action.payload.data,
            page: action.payload.page,
            loading: false,
          }
        }
        case CustomerActionTypes.CREATE_CUSTOMER: {
          let { customers = [] } = state,
            idx = findSlice(customers, (action.payload.data as Customer).id!);

          return {
            ...state,
            customers: [...customers.slice(0, idx), action.payload.data, ...customers.slice(idx + 1)],
            loading: false,
          }
        }
        case CustomerActionTypes.UPDATE_CUSTOMER: {
          let { customers = [] } = state,
            idx = findSlice(customers, action.payload.id!);
          return {
            ...state,
            // customers: [...customers.slice(0, idx), action.payload.data, ...customers.slice(idx + 1)],
            loading: false,
          }
        }
        case CustomerActionTypes.GET_CUSTOMER: {
          let { customers = [] } = state,
            idx = findSlice(customers, action.payload.id!);
          return {
            ...state,
            customers: [...customers.slice(0, idx), action.payload.data, ...customers.slice(idx + 1)],
            loading: false,
          }
        }
        case CustomerActionTypes.DELETE_CUSTOMER: {
          return {
            ...state,
            loading: false,
          }
        }
        case CustomerActionTypes.APPEND_CUSTOMER: {
          return {
            ...state,
            loading: false,
          }
        }
        case CustomerActionTypes.REMOVE_CUSTOMER: {
          return {
            ...state,
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case CustomerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CustomerActionTypes.LIST_CUSTOMERS: {
          return {
            ...state,
            error: action.payload.error,
            loding: false,
          }
        }
        case CustomerActionTypes.UPDATE_CUSTOMER: {
          return {
            ...state,
            error: action.payload.error,
            loding: false,
          }
        }
        case CustomerActionTypes.GET_CUSTOMER: {
          return {
            ...state,
            error: action.payload.error,
            loding: false,
          }
        }
        case CustomerActionTypes.DELETE_CUSTOMER: {
          return {
            ...state,
            error: action.payload.error,
            loding: false,
          }
        }
        default:
          return { ...state };
      }
    case CustomerActionTypes.LIST_CUSTOMERS:
      return { ...state, loading: true }
    case CustomerActionTypes.CREATE_CUSTOMER:
      return { ...state, loading: true }
    case CustomerActionTypes.UPDATE_CUSTOMER:
      return { ...state, loading: false }
    case CustomerActionTypes.GET_CUSTOMER:
      return { ...state, loading: false }
    case CustomerActionTypes.DELETE_CUSTOMER:
      return { ...state, loading: true }
    case CustomerActionTypes.APPEND_CUSTOMER:
      return { ...state, loading: true }
    case CustomerActionTypes.REMOVE_CUSTOMER:
      return { ...state, loading: true }
    case CustomerActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Customers;
