import originAxios from 'axios'

const originAxiosInstance = originAxios.create({
  baseURL: process.env.REACT_APP_ORDERS_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

export const listExtOrders = (params: {
  'enterprise_id'?: string | number,
  'page'?: number,
  'pageSize'?: number
} = {}) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/extorders?' + new URLSearchParams(params as any)).then(res => {
    return res.json()
  })
  return response
}

export const orderAction = (OrderId: number | string, data: any) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/extorders/${OrderId}/action`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}