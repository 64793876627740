import { UserType } from "redux/actions";
import { Page } from "redux/common";
import { EnterpriseActionTypes } from "./constants";

export type EnterpriseActionType = {
  type:
  | EnterpriseActionTypes.API_RESPONSE_SUCCESS
  | EnterpriseActionTypes.API_RESPONSE_ERROR
  | EnterpriseActionTypes.LIST_ENTERPRISES
  | EnterpriseActionTypes.CREATE_ENTERPRISE
  | EnterpriseActionTypes.DELETE_ENTERPRISE
  | EnterpriseActionTypes.LIST_MEMBERS
  | EnterpriseActionTypes.DELETE_MEMBER
  | EnterpriseActionTypes.ADD_MEMBER
  | EnterpriseActionTypes.RESET
  payload: {} | string;
}

export interface EnterpriseType {
  id: number
  enterpriseId: number
  provider: string
  sourceId: string
  name: string
  username: string
  telephone: string
  clickId: string
  items: any[]
  total: number
  price: number
  quantity: number
  createdTime: string
  updatedTime: string
}

export interface CreateEnterpriseType {
  name: string;
  description: string;
  parentId?: number
}

export interface Members {
  id: number
  userId: number
  enterpriseId: number
  user: UserType
  createdAt: string
  updatedAt: string
}

export interface AddMember {
  userId: number
}

export const enterpriseApiResponseSuccess = (actionType: string, data: EnterpriseType | Members | {}, page?: Page): EnterpriseActionType => ({
  type: EnterpriseActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const enterpriseApiResponseError = (actionType: string, data: EnterpriseType | Members | {}): EnterpriseActionType => ({
  type: EnterpriseActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listEnterprises = (page: number = 1, pageSize: number = 20): EnterpriseActionType => ({
  type: EnterpriseActionTypes.LIST_ENTERPRISES,
  payload: {
    page,
    pageSize,
  },
})

export const createEnterprise = (data?: CreateEnterpriseType): EnterpriseActionType => ({
  type: EnterpriseActionTypes.CREATE_ENTERPRISE,
  payload: {
    data
  },
})

export const deleteEnterprise = (enterpriseId: number): EnterpriseActionType => ({
  type: EnterpriseActionTypes.DELETE_ENTERPRISE,
  payload: {
    id: enterpriseId
  },
})

export const listMembers = (enterpriseId: number | string, page: number = 1, pageSize: number = 20): EnterpriseActionType => ({
  type: EnterpriseActionTypes.LIST_MEMBERS,
  payload: {
    id: enterpriseId,
    page,
    pageSize,
  },
})

export const deleteMember = (enterpriseId: number | string, memberId: number): EnterpriseActionType => ({
  type: EnterpriseActionTypes.DELETE_MEMBER,
  payload: {
    enterpriseId,
    id: memberId
  },
})

export const addMember = (enterpriseId: number | string, data: AddMember | {}): EnterpriseActionType => ({
  type: EnterpriseActionTypes.ADD_MEMBER,
  payload: {
    enterpriseId,
    data
  },
})

export const resetEnterpriseType = (): EnterpriseActionType => ({
  type: EnterpriseActionTypes.RESET,
  payload: {},
})