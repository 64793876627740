import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listEnterprises as listEnterprisesApi,
  createEnterprise as createEnterpriseApi,
  deleteEnterprise as deleteEnterpriseApi,
  listMembers as listMembersApi,
  deleteMember as deleteMemberApi,
} from 'helpers';
import { enterpriseApiResponseSuccess, enterpriseApiResponseError, EnterpriseType, Members } from './actions';
import { EnterpriseActionTypes } from './constants';

type EnterprisePayload = {
  payload: {
    id?: number,
    query?: string;
    enterpriseId?: number,
    data: EnterpriseType[] | EnterpriseType | Members[] | {},
    page?: number
    pageSize?: number
  }
  type: string
}

function* listEnterprises({ payload: { query, page = 1,
  pageSize }, type }: EnterprisePayload): SagaIterator {
  try {
    const response = yield call(listEnterprisesApi, query, page, pageSize);
    const { enterprise, page: {
      totalCounts
    } } = response.data;
    yield put(enterpriseApiResponseSuccess(EnterpriseActionTypes.LIST_ENTERPRISES, enterprise, {
      pageCount: totalCounts,
    }))
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterpriseActionTypes.LIST_ENTERPRISES, error));
  }
}

function* createEnterprise({ payload: { data }, type }: EnterprisePayload): SagaIterator {
  try {
    const response = yield call(createEnterpriseApi, data);
    yield put(enterpriseApiResponseSuccess(EnterpriseActionTypes.CREATE_ENTERPRISE, response.data.enterprise))
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterpriseActionTypes.CREATE_ENTERPRISE, error));
  }
}

function* deleteEnterprise({ payload: { id }, type }: EnterprisePayload): SagaIterator {
  try {
    const response = yield call(deleteEnterpriseApi, id!);
    yield put(enterpriseApiResponseSuccess(EnterpriseActionTypes.DELETE_ENTERPRISE, { id }))
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterpriseActionTypes.DELETE_ENTERPRISE, error));
  }
}

function* listMembers({ payload: { id, page = 1,
  pageSize }, type }: EnterprisePayload): SagaIterator {
  try {
    const response = yield call(listMembersApi, id!, {
      'page.page': page,
      'page.pageSize': pageSize,
    });
    const { members, page: {
      totalCounts
    } } = response.data;
    yield put(enterpriseApiResponseSuccess(EnterpriseActionTypes.LIST_MEMBERS, members, {
      pageCount: totalCounts,
    }))
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterpriseActionTypes.LIST_MEMBERS, error));
  }
}

function* deleteMember({ payload: { enterpriseId, id }, type }: EnterprisePayload): SagaIterator {
  try {
    const response = yield call(deleteMemberApi, enterpriseId!, id!);
    yield put(enterpriseApiResponseSuccess(EnterpriseActionTypes.DELETE_MEMBER, { enterpriseId, id }))
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterpriseActionTypes.DELETE_MEMBER, error));
  }
}

function* watchListEnterprises() {
  yield takeEvery(EnterpriseActionTypes.LIST_ENTERPRISES, listEnterprises);
}

function* watchCreateEnterprise() {
  yield takeEvery(EnterpriseActionTypes.CREATE_ENTERPRISE, createEnterprise);
}

function* watchDeleteEnterprise() {
  yield takeEvery(EnterpriseActionTypes.DELETE_ENTERPRISE, deleteEnterprise);
}

function* watchListMembers() {
  yield takeEvery(EnterpriseActionTypes.LIST_MEMBERS, listMembers);
}

function* watchDeleteMember() {
  yield takeEvery(EnterpriseActionTypes.DELETE_MEMBER, deleteMember);
}

// function* watchAddMember() {
//   yield takeEvery(EnterpriseActionTypes.ADD_MEMBER, addMember);
// }

function* enterpriseSaga() {
  yield all([fork(watchListEnterprises), fork(watchCreateEnterprise), fork(watchDeleteEnterprise), fork(watchListMembers), fork(watchDeleteMember)])
}

export default enterpriseSaga;