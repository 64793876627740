import { Page } from 'redux/common';
import { CustomerActionTypes } from './constants'

export type CustomerActionType = {
  type:
  | CustomerActionTypes.API_RESPONSE_SUCCESS
  | CustomerActionTypes.API_RESPONSE_ERROR
  | CustomerActionTypes.LIST_CUSTOMERS
  | CustomerActionTypes.CREATE_CUSTOMER
  | CustomerActionTypes.UPDATE_CUSTOMER
  | CustomerActionTypes.DELETE_CUSTOMER
  | CustomerActionTypes.APPEND_CUSTOMER
  | CustomerActionTypes.REMOVE_CUSTOMER
  | CustomerActionTypes.GET_CUSTOMER
  | CustomerActionTypes.RESET

  payload: {} | string;
};

export interface Customer {
  disable: boolean
  id: number
  weixin: string
  phonenumber?: string
  qrcodeUrl: string
  enterpriseId?: number
  adsetId?: number
  dailyAddCustomers?: number
  addCustomers?: number
  createdAt?: string
  updatedAt?: string
}


export const custmoerApiResponseSuccess = (actionType: string, data: Customer | {}, page?: Page): CustomerActionType => ({
  type: CustomerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const custmoerApiResponseError = (actionType: string, data: Customer | {}): CustomerActionType => ({
  type: CustomerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listCustomers = (enterpriseId?: number, page: number = 1, pageSize: number = 20, adsetId?: number | string,): CustomerActionType => ({
  type: CustomerActionTypes.LIST_CUSTOMERS,
  payload: {
    enterpriseId,
    page,
    pageSize,
    adsetId
  },
})

export const createCustomer = (enterpriseId: number, customer: Customer): CustomerActionType => ({
  type: CustomerActionTypes.CREATE_CUSTOMER,
  payload: {
    enterpriseId,
    data: customer
  },
})

export const updateCustomerService = (
  enterpriseId: number, customerId: number | string, data: any): CustomerActionType => ({
    type: CustomerActionTypes.UPDATE_CUSTOMER,
    payload: {
      enterpriseId,
      id: customerId,
      data,
    },
  })

export const deleteCustomer = (enterpriseId: number, customerId: number): CustomerActionType => ({
  type: CustomerActionTypes.DELETE_CUSTOMER,
  payload: {
    enterpriseId,
    customerId,
  },
})


export const appendCustomer = (enterpriseId: number, customer: Customer): CustomerActionType => ({
  type: CustomerActionTypes.APPEND_CUSTOMER,
  payload: {
    data: customer,
  },
})

export const removeCustomer = (enterpriseId: number, customerId: number | string, customer: Customer): CustomerActionType => ({
  type: CustomerActionTypes.REMOVE_CUSTOMER,
  payload: {
    enterpriseId,
    id: customerId,
    data: customer,
  },
})

export const getCustomerService = (enterpriseId: number, customerId: number | string): CustomerActionType => ({
  type: CustomerActionTypes.GET_CUSTOMER,
  payload: {
    enterpriseId,
    id: customerId,
  },
})

export const resetCustomer = (): CustomerActionType => ({
  type: CustomerActionTypes.RESET,
  payload: {},
})