export enum AccountActionTypes {
    API_RESPONSE_SUCCESS = '@@account/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@account/API_RESPONSE_ERROR',

    LIST_ACCOUNTS = '@@account/LIST_ACCOUNTS',
    LIST_USER_ACTION_SETS = '@@account/LIST_USER_ACTION_SETS',
    GET_ACCOUNT = '@@account/GET_ADSET',
    LIST_AGENT_ACCOUNTS = '@@account/LIST_AGENT_ACCOUNTS',
    RESET = '@@account/RESET',
}
