import { entPath } from './adset';
import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

// export function listProducts(enterpriseId?: number, params: {
//   'page.page'?: number, // 页起始位置
//   'page.pageSize'?: number // 页大小
// } = {}) {

//   return api.get(entPath("products", enterpriseId), params);
// }

export function listProducts(enterpriseId?: number, provider?: string, page?: number, pageSize?: number) {
  let filters = [];
  if (provider) {
    filters.push(JSON.stringify({ "ID": "Provider", "Value": provider }))
  }
  const params = {
    'page.page': page || 0,
    'page.pageSize': pageSize || 0,
    filters
  }
  return api.get(entPath("products", enterpriseId), params);
}

export function importProduct(data: any) {
  return api.create(`${baseUrl}/products/import`, data);
}

export function syncProduct(productId: string | number) {
  return api.update(`${baseUrl}/products/${productId}/sync`, {});
}

export function deleteProduct(productId: string | number) {
  return api.delete(`${baseUrl}/products/${productId}`);
}