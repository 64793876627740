import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore } from 'helpers/api/apiCore';
import { settingApiResponseError, settingApiResponseSuccess } from './actions';
import { SettingActionTypes } from './constants';
import {
    getSettings as getSettingsApi,
    setSettings as setSettingsApi,
} from 'helpers/api/settings';

const api = new APICore();

type SettingData = {
    type: string;
    payload: {
        configKey: string,
        settings?: any
    };
}

function* getSettings({ payload: { configKey } }: SettingData): SagaIterator {
    try {
        const response = yield call(getSettingsApi, { configKey });
        const settings = response.data;
        yield put(settingApiResponseSuccess(SettingActionTypes.GET_SETTINGS, settings));
    } catch (error: any) {
        yield put(settingApiResponseError(SettingActionTypes.GET_SETTINGS, error));
    }
}

function* setSettings({ payload: { configKey, settings } }: SettingData): SagaIterator {
    try {
        const response = yield call(setSettingsApi, { configKey, settings: JSON.stringify(settings) });
        const rsettings = response.data;
        yield put(settingApiResponseSuccess(SettingActionTypes.SET_SETTINGS, rsettings));
    } catch (error: any) {
        yield put(settingApiResponseError(SettingActionTypes.SET_SETTINGS, error));
    }
}

export function* watchGetSettings() {
    yield takeEvery(SettingActionTypes.GET_SETTINGS, getSettings);
}

export function* watchSetSettings() {
    yield takeEvery(SettingActionTypes.SET_SETTINGS, setSettings);
}

function* settingsSaga() {
    yield all([fork(watchGetSettings), fork(watchSetSettings)]);
}

export default settingsSaga;