import { Page } from 'redux/common';
import { ThirdpartyApp } from './actions';
import { ThirdpartyAppsActionTypes } from './constants';


const INIT_STATE = {
    thirdpartyApps: [],
    loading: false,
};


type ThirdpartyAppsActionType = {
    type:
    | ThirdpartyAppsActionTypes.API_RESPONSE_SUCCESS
    | ThirdpartyAppsActionTypes.API_RESPONSE_ERROR
    | ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS
    | ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS
    | ThirdpartyAppsActionTypes.GET_ACCESSTOKEN
    | ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP
    | ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP
    | ThirdpartyAppsActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: ThirdpartyApp[] | ThirdpartyApp | {};
        id?: number;
        error?: string;
        errorCode?: number;
        page: Page,
    };
};

type State = {
    thirdpartyApps?: ThirdpartyApp[];
    loading?: boolean;
};

const findSlice = (thirdpartyApps: ThirdpartyApp[], thirdpartyId: number): number => {
    let idx = thirdpartyApps.findIndex((a) => a.id === thirdpartyId);
    return idx < 0 ? thirdpartyApps.length : idx;
}

const ThirdpartyApps = (state: State = INIT_STATE, action: ThirdpartyAppsActionType) => {
    switch (action.type) {
        case ThirdpartyAppsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS:
                    return {
                        ...state,
                        thirdpartyApps: action.payload.data,
                        loading: false,
                        page: action.payload.page,

                    }
                case ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS:
                    return {
                        ...state,
                        thirdpartyApps: action.payload.data,
                        loading: false,
                        page: action.payload.page,
                    }
                case ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP: {
                    let { thirdpartyApps = [] } = state,
                        idx = findSlice(thirdpartyApps, (action.payload.data as ThirdpartyApp).id);
                    return {
                        ...state,
                        thirdpartyApps: [...thirdpartyApps.slice(0, idx), action.payload.data, ...thirdpartyApps.slice(idx + 1)],
                        loading: false,
                    }
                }
                case ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP: {
                    let { thirdpartyApps = [] } = state,
                        idx = findSlice(thirdpartyApps, (action.payload.data as ThirdpartyApp).id);
                    thirdpartyApps.slice(idx, 1)
                    return {
                        ...state,
                        thirdpartyApps: [...thirdpartyApps],
                        loading: false,
                    }
                }
                default:
                    return { ...state };
            }
        case ThirdpartyAppsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                case ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                case ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                default:
                    return { ...state };
            }
        case ThirdpartyAppsActionTypes.LIST_THIRDPARTYAPPS, ThirdpartyAppsActionTypes.LIST_ENTERPRISE_THIRDPARTYAPPS:
            return { ...state, loading: true }
        case ThirdpartyAppsActionTypes.PUT_THIRDPARTYAPP:
            return { ...state, loading: true }
        case ThirdpartyAppsActionTypes.DELETE_THIRDPARTYAPP:
            return { ...state, loading: true }
        case ThirdpartyAppsActionTypes.RESET:
            return {
                ...state,
                ...INIT_STATE,
            }
        default:
            return { ...state }
    }
}

export default ThirdpartyApps