import { Page } from 'redux/common';
import { FormsActionTypes } from './constants'

export type FormsActionType = {
  type:
  | FormsActionTypes.API_RESPONSE_SUCCESS
  | FormsActionTypes.API_RESPONSE_ERROR
  | FormsActionTypes.LIST_FORM
  | FormsActionTypes.UPDATE_FORM
  | FormsActionTypes.DELETE_FORM
  | FormsActionTypes.GET_FORM
  | FormsActionTypes.RESET

  payload: {} | string;
};

export interface FormType {
  id: number
  enterpriseId: number
  schemaId: number
  adsetId: number
  enterprise: any
  form: string
  formTitle: string
  createdTime: string
  updatedTime: string
}

export const formApiResponseSuccess = (actionType: string, data: FormType | {}, page?: Page): FormsActionType => ({
  type: FormsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const formApiResponseError = (actionType: string, data: FormType | {}): FormsActionType => ({
  type: FormsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listForms = (enterpriseId: number, page: number = 1, pageSize: number = 20): FormsActionType => ({
  type: FormsActionTypes.LIST_FORM,
  payload: {
    enterpriseId,
    page,
    pageSize,
  },
})

export const updateForm = (
  enterpriseId: number, FormId: number | string, FormType: FormType): FormsActionType => ({
    type: FormsActionTypes.UPDATE_FORM,
    payload: {
      enterpriseId,
      id: FormId,
      data: FormType,
    },
  })

export const deleteForm = (enterpriseId: number, FormId: number | string, FormType: FormType): FormsActionType => ({
  type: FormsActionTypes.DELETE_FORM,
  payload: {
    enterpriseId,
    id: FormId,
    data: FormType,
  },
})

export const getForm = (enterpriseId: number, FormId: number | string): FormsActionType => ({
  type: FormsActionTypes.GET_FORM,
  payload: {
    id: FormId,
  },
})

export const resetFormType = (): FormsActionType => ({
  type: FormsActionTypes.RESET,
  payload: {},
})