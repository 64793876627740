import { CreatekfAccountDataType } from "pages/apps/Adaccount/types";

interface AuthParams {
  suite_id: string;
  auth_type: string;
}

interface KfAccountParams {
  limit: string;
  corp_id: string;
}

interface UploadMediaParams {
  type: string
  corp_id: string
}

interface DeletekfAccount {
  corpId: string
  name: string
  mediaId: string
}

export const authorizedEnterpriseWeChat = () => {
  const params: AuthParams = {
    suite_id: 'wwc1e681a5f35486da',
    auth_type: 'AUTH_TYPE_WXWORK_KF',
  };
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/wxwork/auth_url?' + new URLSearchParams(params as any)).then(res => {
    return res.json()
  })
  return response
}

export const listKfAccounts = (corp_id: string) => {
  const params: KfAccountParams = {
    limit: '100',
    corp_id: corp_id,
  };
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/wxwork/kf_account_list?' + new URLSearchParams(params as any)).then(res => {
    return res.json()
  })
  return response
}

export const uploadMedia = (corp_id: string, media: File) => {
  const params: UploadMediaParams = {
    type: 'image',
    corp_id: corp_id,
  };

  const url = process.env.REACT_APP_ORDERS_API_URL +
    `/api/v1/wxwork/upload_media?${new URLSearchParams(params as any).toString()}`;

  const formData = new FormData();
  formData.append('media', media);

  let response = fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  return response;
};

export const createkfAccount = (data: CreatekfAccountDataType) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/wxwork/create_kf_account`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const deletekfAccount = (open_kfid: string, data: DeletekfAccount) => {
  const params = {
    open_kfid
  }
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/wxwork/delete_kf_account?' + new URLSearchParams(params as any), {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}


