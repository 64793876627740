import { Page } from 'redux/common';
import { AuthorizedEnterprise } from './actions';
import { AuthorizedEnterpriseActionTypes } from './constants';

const INIT_STATE = {
  authCorps: [],
  loading: false,
};

type AuthorizedEnterpriseActionType = {
  type:
  | AuthorizedEnterpriseActionTypes.API_RESPONSE_SUCCESS
  | AuthorizedEnterpriseActionTypes.API_RESPONSE_ERROR
  | AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES
  | AuthorizedEnterpriseActionTypes.RESET

  payload: {
    actionType?: string;
    data?: AuthorizedEnterprise[] | AuthorizedEnterprise | {};
    error?: string;
    id?: number;
    page: Page;
  }
}

type State = {
  authCorps?: AuthorizedEnterprise[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: AuthorizedEnterprise[], authCorpsId: number): number => {
  let idx = data.findIndex((a) => a.id === authCorpsId);
  return idx < 0 ? data.length : idx;
}

const AuthorizedEnterprises = (state: State = INIT_STATE, action: AuthorizedEnterpriseActionType) => {
  switch (action.type) {
    case AuthorizedEnterpriseActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES: {
          return {
            ...state,
            authCorps: action.payload.data,
            page: action.payload.page,
            loading: false,
          }
        }
        default:
          return { ...state };
      }
    case AuthorizedEnterpriseActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES: {
          return {
            ...state,
            error: action.payload.error,
            loding: false,
          }
        }
        default:
          return { ...state };
      }
    case AuthorizedEnterpriseActionTypes.LIST_AUTHORIZEDENTERPRISES:
      return { ...state, loading: true }
    case AuthorizedEnterpriseActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default AuthorizedEnterprises;
