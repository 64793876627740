import { Page } from 'redux/common';
import { sliceFind } from 'redux/utils';
import { Account, UserActionSet } from './actions';
import { AccountActionTypes } from './constants';

const INIT_STATE = {
    accounts: [],
    oceanengineAccounts: [],
    pinduoduoAccounts: [],
    userActionSets: [],
    loading: false,
};

type AccountActionType = {
    type:
    | AccountActionTypes.API_RESPONSE_SUCCESS
    | AccountActionTypes.API_RESPONSE_ERROR
    | AccountActionTypes.LIST_ACCOUNTS
    | AccountActionTypes.GET_ACCOUNT
    | AccountActionTypes.LIST_USER_ACTION_SETS
    | AccountActionTypes.LIST_AGENT_ACCOUNTS
    | AccountActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: Account[] | Account | UserActionSet[] | {};
        id?: number;
        error?: string;
        errorCode?: number;
        provider?: string;
        page: Page,
    };
};

type State = {
    accounts?: Account[];
    oceanengineAccounts?: Account[];
    pinduoduoAccounts?: Account[];
    userActionSets?: UserActionSet[];
    loading?: boolean;
};

const Accounts = (state: State = INIT_STATE, action: AccountActionType) => {
    switch (action.type) {
        case AccountActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AccountActionTypes.LIST_ACCOUNTS: {
                    let { provider } = action.payload
                    switch (provider) {
                        case 'tencent_gdt':
                            return {
                                ...state,
                                accounts: action.payload.data,
                                loading: false,
                                tencent_gdt_page: action.payload.page,
                            }
                        case 'oceanengine':
                            return {
                                ...state,
                                oceanengineAccounts: action.payload.data,
                                loading: false,
                                oceanengine_page: action.payload.page,
                            }
                        case 'pingduoduo':
                            return {
                                ...state,
                                pinduoduoAccounts: action.payload.data,
                                loading: false,
                                pingduoduo_page: action.payload.page,
                            }
                        default:
                            return {
                                ...state,
                                accounts: action.payload.data,
                                loading: false,
                                page: action.payload.page,
                            };
                    }
                }
                case AccountActionTypes.GET_ACCOUNT: {
                    let { accounts = [] } = state,
                        idx = sliceFind(accounts, action.payload.id!);
                    return {
                        ...state,
                        adsets: [...accounts.slice(0, idx), action.payload.data, ...accounts.slice(idx + 1)],
                        loading: false,
                    }
                }
                case AccountActionTypes.LIST_USER_ACTION_SETS: {
                    return {
                        ...state,
                        userActionSets: action.payload.data,
                        loading: false,
                    }
                }
                case AccountActionTypes.LIST_AGENT_ACCOUNTS: {
                    return {
                        ...state,
                        formSchemas: action.payload.data,
                        loading: false,
                    }
                }
                default:
                    return { ...state };
            }
        case AccountActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AccountActionTypes.LIST_ACCOUNTS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        laoding: false,
                    }
                }
                case AccountActionTypes.GET_ACCOUNT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        laoding: false,
                    }
                }
                case AccountActionTypes.LIST_USER_ACTION_SETS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        errorCode: action.payload.errorCode,
                        laoding: false,
                    }
                }
                case AccountActionTypes.LIST_AGENT_ACCOUNTS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                default:
                    return { ...state };
            }
        case AccountActionTypes.LIST_ACCOUNTS:
            return { ...state, loading: true }
        case AccountActionTypes.GET_ACCOUNT:
            return { ...state, loading: true }
        case AccountActionTypes.LIST_USER_ACTION_SETS:
            return { ...state, loading: true }
        case AccountActionTypes.LIST_AGENT_ACCOUNTS:
            return { ...state, loading: true }
        case AccountActionTypes.RESET:
            return {
                ...state,
                ...INIT_STATE,
            }
        default:
            return { ...state };
    }
}

export default Accounts;