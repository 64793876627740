import { SettingActionTypes } from './constants';

export type SettingsActionType = {
    type:
    SettingActionTypes.API_RESPONSE_SUCCESS |
    SettingActionTypes.API_RESPONSE_ERROR |
    SettingActionTypes.GET_SETTINGS |
    SettingActionTypes.SET_SETTINGS;
    payload: {};
}

type Setting = {
    schema: any;
    ui: any;
    settings: any;
}

export const settingApiResponseSuccess = (actionType: string, data?: Setting): SettingsActionType => ({
    type: SettingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const settingApiResponseError = (actionType: string, error: string): SettingsActionType => ({
    type: SettingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});


export const getSetting = (configKey: string): SettingsActionType => ({
    type: SettingActionTypes.GET_SETTINGS,
    payload: { configKey },
});

export const setSetting = (configKey: string, settings: any): SettingsActionType => ({
    type: SettingActionTypes.SET_SETTINGS,
    payload: { configKey, settings },
});