import { entPath } from './adset';
import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

function listAccounts(enterpriseId?: number, q?: string, provider?: string, page?: number, pageSize?: number) {
    let filters = [];
    if (q) {
        filters.push(JSON.stringify({ "ID": "Q", "Value": q }))
    } else if (provider) {
        filters.push(JSON.stringify({ "ID": "Provider", "Value": provider }))
    }
    const params = {
        'page.page': page || 0,
        'page.pageSize': pageSize || 0,
        filters
    }
    return api.get(entPath("accounts", enterpriseId), params);
}

function getAccount(id: number | string) {
    return api.get(`${baseUrl}/accounts/${id}`, {});
}

function listUserActionSets(id: number | string) {
    return api.get(`${baseUrl}/accounts/${id}/user_action_sets`, {});
}

function listImportSchemes() {
    return api.get(`${baseUrl}/accounts/import_schemas`, {})
}

function listAgentAccounts(AccountId: number, childAccounts?: string) {
    const params: any = {}
    if (childAccounts) {
        params.childAccounts = childAccounts
    }
    return api.get(`${baseUrl}/accounts/${AccountId}/agent_accounts`, params);
}

function importAgentAccount(AccountId: number, data: any) {
    return api.create(`${baseUrl}/accounts/${AccountId}/import`, data);
}

function getAccountToken(AccountId: number, view_token?: number) {
    return api.get(`${baseUrl}/accounts/${AccountId}`, { view_token });
}

function grantToEnterprise(AccountId: number, enterpriseId?: number) {
    return api.update(`${baseUrl}/accounts/${AccountId}/grant`, { 'enterpriseId': enterpriseId });
}

function revokeAuthorization(AccountId: number, enterpriseId?: number) {
    return api.update(`${baseUrl}/accounts/${AccountId}/revoke`, { 'enterpriseId': enterpriseId });
}

function listAuthorizedEnterprises(enterpriseId?: number) {
    const params = { enterpriseId }

    if (!enterpriseId) {
        delete params.enterpriseId;
    }
    let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/wxwork/list_auth_corps?' + new URLSearchParams(params as any)).then(res => {
        return res.json()
    })
    return response
}

export { listAccounts, listUserActionSets, getAccount, listImportSchemes, listAgentAccounts, importAgentAccount, getAccountToken, grantToEnterprise, revokeAuthorization, listAuthorizedEnterprises }