import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listCustomers as listCustomersApi,
  createCustomer as createCustomerApi,
  updateCustomerService as updateCustomerServiceApi,
  deleteCustomer as deleteCustomersApi,
  getCustomerService as getCustomerServiceApi
} from 'helpers';
import { custmoerApiResponseSuccess, custmoerApiResponseError, Customer } from './actions';
import { CustomerActionTypes } from './constants';
import { buildFilters } from 'redux/utils';

type CustomerPayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: Customer[] | Customer | {};
    page?: number,
    pageSize?: number,
    adsetId?: number,
  }
  type: string
}

function* listCustomers({ payload: { enterpriseId, page = 1, pageSize, adsetId }, type }: CustomerPayload): SagaIterator {
  try {

    const response = yield call(listCustomersApi, enterpriseId, {
      'page.page': page,
      'page.pageSize': pageSize,
      'filters': buildFilters({ "AdsetId": adsetId }),
    });
    const { customerServices, page: { totalCounts } } = response.data;
    yield put(custmoerApiResponseSuccess(CustomerActionTypes.LIST_CUSTOMERS, customerServices, { pageCount: totalCounts }))
  } catch (error: any) {
    yield put(custmoerApiResponseError(CustomerActionTypes.LIST_CUSTOMERS, error));
  }
}

function* createCustomer({ payload: { enterpriseId, data }, type }: CustomerPayload): SagaIterator {
  try {
    const response = yield call(createCustomerApi, enterpriseId, data);
    const { customerService } = response.data;
    yield put(custmoerApiResponseSuccess(CustomerActionTypes.CREATE_CUSTOMER, customerService,))
  } catch (error: any) {
    yield put(custmoerApiResponseError(CustomerActionTypes.CREATE_CUSTOMER, error));
  }
}

function* updateCustomerService({ payload: { enterpriseId, id, data }, type }: CustomerPayload): SagaIterator {
  try {
    const response = yield call(updateCustomerServiceApi, enterpriseId, id, data);
    const { customerService } = response.data;
    yield put(custmoerApiResponseSuccess(CustomerActionTypes.UPDATE_CUSTOMER, customerService,))
  } catch (error: any) {
    yield put(custmoerApiResponseError(CustomerActionTypes.UPDATE_CUSTOMER, error));
  }
}

function* getCustomerService({ payload: { enterpriseId, id }, type }: CustomerPayload): SagaIterator {
  try {
    const response = yield call(getCustomerServiceApi, enterpriseId, id);
    const { customerService } = response.data;
    yield put(custmoerApiResponseSuccess(CustomerActionTypes.GET_CUSTOMER, customerService,))
  } catch (error: any) {
    yield put(custmoerApiResponseError(CustomerActionTypes.GET_CUSTOMER, error));
  }
}

function* deleteCustomer({ payload: { enterpriseId, id }, type }: CustomerPayload): SagaIterator {
  try {
    const response = yield call(deleteCustomersApi, enterpriseId, id);
    const { customerService } = response.data;
    yield put(custmoerApiResponseSuccess(CustomerActionTypes.DELETE_CUSTOMER, customerService,))
  } catch (error: any) {
    yield put(custmoerApiResponseError(CustomerActionTypes.DELETE_CUSTOMER, error));
  }
}


function* watchListCustomers() {
  yield takeEvery(CustomerActionTypes.LIST_CUSTOMERS, listCustomers);
}

function* watchCreateCustomer() {
  yield takeEvery(CustomerActionTypes.CREATE_CUSTOMER, createCustomer);
}

function* watchDeleteCustomer() {
  yield takeEvery(CustomerActionTypes.DELETE_CUSTOMER, deleteCustomer);
}

function* watchUpdateCustomerService() {
  yield takeEvery(CustomerActionTypes.UPDATE_CUSTOMER, updateCustomerService);
}

function* watchGetCustomerService() {
  yield takeEvery(CustomerActionTypes.GET_CUSTOMER, getCustomerService);
}

function* customerSaga() {
  yield all([fork(watchListCustomers), fork(watchCreateCustomer), fork(watchDeleteCustomer), fork(watchUpdateCustomerService), fork(watchGetCustomerService)])
}

export default customerSaga;