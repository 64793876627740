export const listService = (params: {
  'page'?: number,
  'pageSize'?: number
} = {}) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + '/api/v1/service?' + new URLSearchParams(params as any)).then(res => {
    return res.json()
  })
  return response
}

export const createService = (data: any) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/service`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const updateService = (serviceId: any, data: any) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/service/${serviceId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const getService = (serviceId: any) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/service/${serviceId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}

export const deleteService = (serviceId: any) => {
  let response = fetch(process.env.REACT_APP_ORDERS_API_URL + `/api/v1/service/${serviceId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => {
    return res.json()
  })
  return response
}