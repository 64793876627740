import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

export function listEnterprises(q?: string, page?: number, pageSize?: number, parentId?: number) {
  let filters = [];
  if (q) {
    filters.push(JSON.stringify({ "ID": "Q", "Value": q }))
  }
  const params: any = {
    'page.page': page || 0,
    'page.pageSize': pageSize || 0,
    filters,
  }
  if (parentId) {
    params['parentId'] = parentId
  }
  return api.get(`${baseUrl}/enterprise`, params);
}

export function createEnterprise(data: any) {
  return api.create(`${baseUrl}/enterprise`, data);
}

export function deleteEnterprise(enterpriseId: number) {
  return api.delete(`${baseUrl}/enterprise/${enterpriseId}`);
}

export function addMember(enterpriseId: number | string, data: any) {
  return api.update(`${baseUrl}/enterprise/${enterpriseId}/members`, data);
}

export function listMembers(enterpriseId?: number | string, params: {
  'page.page'?: number, // 页起始位置
  'page.pageSize'?: number // 页大小
} = {}) {
  return api.get(`${baseUrl}/enterprise/${enterpriseId}/members`, params);
}

export function deleteMember(enterpriseId: number, memberId: number) {
  return api.delete(`${baseUrl}/enterprise/${enterpriseId}/members/${memberId}`);
}