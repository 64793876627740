export enum FormSchemaActionTypes {
  API_RESPONSE_SUCCESS = '@@forms/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@forms/API_RESPONSE_ERROR',

  LIST_FORMSCHEMAS = '@@forms/LIST_FORMSCHEMAS',
  CREATE_FORMSCHEMA = '@@forms/CREATE_FORMSCHEMA',
  UPDATE_FORMSCHEMA = '@@forms/UPDATE_FORMSCHEMA',
  GET_FORMSCHEMA = '@@forms/GET_FORMSCHEMA',
  DELETE_FORMSCHEMA = '@@forms/DELETE_FORMSCHEMA',

  RESET = '@@forms/RESET',
}