import { Page } from "redux/common";
import { ServiceActionTypes } from "./constants";

export type ServiceActionType = {
  type:
  | ServiceActionTypes.API_RESPONSE_SUCCESS
  | ServiceActionTypes.API_RESPONSE_ERROR
  | ServiceActionTypes.LIST_SERVICES
  | ServiceActionTypes.UPDATE_SERVICE
  | ServiceActionTypes.GET_SERVICE
  | ServiceActionTypes.RESET
  payload: {} | string;
}

export interface ServiceType {
  id: number
  name: string
  description: string
  provider: string
  attributes: SinonAttributes | ZopAttributes
}

export interface SinonAttributes {
  desSecret: string
  loginname: string
  password: string
  productId: string
  publicKey: string
}

export interface ZopAttributes {
  aesKey: string
  appCode: string
  channel: string
  cityCode: string
  feeMode: string
  goodsId: string
  hmacSecret: string
  host: string
  msgChannel: string
  productId: string
  provinceCode: string
  referrerCode: string
}

export const serviceApiResponseSuccess = (actionType: string, data: ServiceType | {}, page?: Page): ServiceActionType => ({
  type: ServiceActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const serviceApiResponseError = (actionType: string, data: ServiceType | {}): ServiceActionType => ({
  type: ServiceActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listServices = (page: number = 0, pageSize: number = 20): ServiceActionType => ({
  type: ServiceActionTypes.LIST_SERVICES,
  payload: {
    page,
    pageSize
  },
})

export const updateService = (serviceId: number | string, data: any): ServiceActionType => ({
  type: ServiceActionTypes.UPDATE_SERVICE,
  payload: {
    id: serviceId,
    data,
  },
})

export const getService = (serviceId: number | string): ServiceActionType => ({
  type: ServiceActionTypes.GET_SERVICE,
  payload: {
    id: serviceId,
  },
})

export const resetServiceType = (): ServiceActionType => ({
  type: ServiceActionTypes.RESET,
  payload: {},
})