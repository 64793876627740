import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listOrders as listOrdersApi,
  deleteOrder as deleteOrderApi
} from 'helpers';
import { ordersApiResponseSuccess, ordersApiResponseError, OrdersType } from './actions';
import { OrdersActionTypes } from './constants';

type OrdersPayload = {
  payload: {
    id?: number,
    enterpriseId?: number,
    data: OrdersType[] | OrdersType | {},
    page?: number
    pageSize?: number
  }
  type: string
}

function* listOrders({ payload: { enterpriseId, page = 1,
  pageSize }, type }: OrdersPayload): SagaIterator {
  try {
    const response = yield call(listOrdersApi, enterpriseId, {
      'page.page': page,
      'page.pageSize': pageSize,
    });
    const { orders, page: {
      totalCounts
    } } = response.data;
    yield put(ordersApiResponseSuccess(OrdersActionTypes.LIST_ORDERS, orders, {
      pageCount: totalCounts,
    }))
  } catch (error: any) {
    yield put(ordersApiResponseError(OrdersActionTypes.LIST_ORDERS, error));
  }
}

function* deleteOrder({ payload: { id }, type }: OrdersPayload): SagaIterator {
  try {
    const response = yield call(deleteOrderApi, id!);
    yield put(ordersApiResponseSuccess(OrdersActionTypes.DELETE_ORDER, { id }))
  } catch (error: any) {
    yield put(ordersApiResponseError(OrdersActionTypes.DELETE_ORDER, error));
  }
}


function* watchListOrders() {
  yield takeEvery(OrdersActionTypes.LIST_ORDERS, listOrders);
}

function* watchDeleteOrder() {
  yield takeEvery(OrdersActionTypes.DELETE_ORDER, deleteOrder);
}


function* ordersSaga() {
  yield all([fork(watchListOrders), fork(watchDeleteOrder)])
}

export default ordersSaga;