import { Page } from 'redux/common';
import { ServiceActionTypes } from "./constants";
import { ServiceType } from "./actions";

const INIT_STATE = {
  services: [],
  loading: false,
};

type ServicesActionType = {
  type:
  | ServiceActionTypes.API_RESPONSE_SUCCESS
  | ServiceActionTypes.API_RESPONSE_ERROR
  | ServiceActionTypes.LIST_SERVICES
  | ServiceActionTypes.UPDATE_SERVICE
  | ServiceActionTypes.GET_SERVICE
  | ServiceActionTypes.RESET
  payload: {
    actionType?: string;
    data?: ServiceType[] | ServiceType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  services?: ServiceType[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: ServiceType[], servicesId: number): number => {
  let idx = data.findIndex((a) => a.id === servicesId);
  return idx < 0 ? data.length : idx;
}

const Services = (state: State = INIT_STATE, action: ServicesActionType) => {
  switch (action.type) {
    case ServiceActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ServiceActionTypes.LIST_SERVICES: {
          return {
            ...state,
            services: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case ServiceActionTypes.UPDATE_SERVICE: {
          let { services = [] } = state,
            idx = findSlice(services, ((action.payload.data as ServiceType).id * 1)),
            id = ((action.payload.data as ServiceType).id * 1),
            service = services[idx]
          return {
            ...state,
            services: [...services.slice(0, idx), { ...service, id }, ...services.slice(idx + 1)],
            loading: false
          }
        }
        case ServiceActionTypes.GET_SERVICE: {
          let { services = [] } = state,
            idx = findSlice(services, ((action.payload.data as ServiceType).id * 1))
          return {
            ...state,
            services: [...services.slice(0, idx), action.payload.data, ...services.slice(idx + 1)],
            loading: false
          }
        }
        default:
          return { ...state };
      }
    case ServiceActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ServiceActionTypes.LIST_SERVICES: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case ServiceActionTypes.UPDATE_SERVICE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case ServiceActionTypes.GET_SERVICE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        default:
          return { ...state }
      }
    case ServiceActionTypes.LIST_SERVICES:
      return { ...state, loading: true }
    case ServiceActionTypes.UPDATE_SERVICE:
      return { ...state, loading: true }
    case ServiceActionTypes.GET_SERVICE:
      return { ...state, loading: true }
    case ServiceActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default Services