import { Page } from 'redux/common';
import { CustomerAcquisitionsActionTypes } from './constants'

export type CustomerAcquisitionsActionType = {
  type:
  | CustomerAcquisitionsActionTypes.API_RESPONSE_SUCCESS
  | CustomerAcquisitionsActionTypes.API_RESPONSE_ERROR
  | CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS
  | CustomerAcquisitionsActionTypes.RESET

  payload: {} | string;
};

export interface CustomerAcquisition {
  id: number
  corpId: string
  openKfid: string
  name: string
  avatar: string
  scene: string
  kfUrl: string
  mediaId: string
  enterpriseId: number
}

export const customerAcquisitionApiResponseSuccess = (actionType: string, data: CustomerAcquisition | {}, page?: Page): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const customerAcquisitionApiResponseError = (actionType: string, error: Error): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listCustomerAcquisition = (corp_id: string, limit: number = 20): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS,
  payload: {
    corp_id,
    limit
  },
})

export const resetCustomerAcquisition = (): CustomerAcquisitionsActionType => ({
  type: CustomerAcquisitionsActionTypes.RESET,
  payload: {},
})