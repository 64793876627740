import { Page } from 'redux/common';
import { YianOrdersActionTypes } from "./constants";
import { YianOrdersType } from "./actions";

const INIT_STATE = {
  yianOrders: [],
  loading: false,
};

type OrdersActionType = {
  type:
  | YianOrdersActionTypes.API_RESPONSE_SUCCESS
  | YianOrdersActionTypes.API_RESPONSE_ERROR
  | YianOrdersActionTypes.LIST_YIANORDERS
  | YianOrdersActionTypes.RESET
  payload: {
    actionType?: string;
    data?: YianOrdersType[] | YianOrdersType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  yianOrders?: YianOrdersType[];
  loading?: boolean;
  error?: string
};

// const findSlice = (data: YianOrdersType[], orderId: number): number => {
//   let idx = data.findIndex((a) => a.id === orderId);
//   return idx < 0 ? data.length : idx;
// }

const YianOrders = (state: State = INIT_STATE, action: OrdersActionType) => {
  switch (action.type) {
    case YianOrdersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case YianOrdersActionTypes.LIST_YIANORDERS: {
          return {
            ...state,
            yianOrders: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        default:
          return { ...state };
      }
    case YianOrdersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case YianOrdersActionTypes.LIST_YIANORDERS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        default:
          return { ...state }
      }
    case YianOrdersActionTypes.LIST_YIANORDERS:
      return { ...state, loading: true }
    case YianOrdersActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default YianOrders