export enum AdsetActionTypes {
    API_RESPONSE_SUCCESS = '@@adset/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@adset/API_RESPONSE_ERROR',

    LIST_ADSETS = '@@adset/LIST_ADSETS',
    GET_ADSET = '@@adset/GET_ADSET',
    PUT_ADSET = '@@adset/PUT_ADSET',
    ACK_PUSH = '@@adset/ACK_PUSH',
    STOP_ADSET = '@@adset/STOP_ADSET',
    START_ADSET = '@@adset/START_ADSET',
    RELENISH_PUSH = '@@adset/RELENISH_PUSH',
    CHANGE_PARAMS = '@@adset/CHANGE_PARAMS',
    CHANGE_PUSHSTRATEGY = '@@adset/CHANGE_PUSHSTRATEGY',
    RESET = '@@adset/RESET',
}
