import { Page } from "redux/common";
import { YianOrdersActionTypes } from "./constants";

export type YianOrdersActionType = {
  type:
  | YianOrdersActionTypes.API_RESPONSE_SUCCESS
  | YianOrdersActionTypes.API_RESPONSE_ERROR
  | YianOrdersActionTypes.LIST_YIANORDERS
  | YianOrdersActionTypes.RESET
  payload: {} | string;
}

export interface YianOrdersType {
  orderId: string
  amount: number
  clickId: string
  createTime: string
  chargeUrl: string
  status: string
}

export const yianOrdersApiResponseSuccess = (actionType: string, data: YianOrdersType | {}, page?: Page): YianOrdersActionType => ({
  type: YianOrdersActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data, page },
});

export const yianOrdersApiResponseError = (actionType: string, data: YianOrdersType | {}): YianOrdersActionType => ({
  type: YianOrdersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, data },
});

export const listYianOrders = (page: number = 0, pageSize: number = 20): YianOrdersActionType => ({
  type: YianOrdersActionTypes.LIST_YIANORDERS,
  payload: {
    page,
    pageSize
  },
})

export const resetYianOrdersType = (): YianOrdersActionType => ({
  type: YianOrdersActionTypes.RESET,
  payload: {},
})