import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listForms as listFormsApi
} from 'helpers';
import { formApiResponseSuccess, formApiResponseError, FormType } from './actions';
import { FormsActionTypes } from './constants';

type FormPayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: FormType[] | FormType | {};
    page?: number
    pageSize?: number
  }
  type: string
}


function* listForms({ payload: { enterpriseId, page = 1,
  pageSize }, type }: FormPayload): SagaIterator {
  try {
    const response = yield call(listFormsApi, enterpriseId, {
      'page.page': page,
      'page.pageSize': pageSize,
    });
    const { forms, page: {
      totalCounts
    } } = response.data;
    yield put(formApiResponseSuccess(FormsActionTypes.LIST_FORM, forms, {
      pageCount: totalCounts
    }))
  } catch (error: any) {
    yield put(formApiResponseError(FormsActionTypes.LIST_FORM, error));
  }
}

function* watchListForms() {
  yield takeEvery(FormsActionTypes.LIST_FORM, listForms);
}

function* formSaga() {
  yield all([fork(watchListForms)])
}

export default formSaga;