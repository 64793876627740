import { Page } from 'redux/common';
import { ExtOrdersActionTypes } from "./constants";
import { ExtOrdersType } from "./actions";

const INIT_STATE = {
  extOrders: [],
  loading: false,
};

type OrdersActionType = {
  type:
  | ExtOrdersActionTypes.API_RESPONSE_SUCCESS
  | ExtOrdersActionTypes.API_RESPONSE_ERROR
  | ExtOrdersActionTypes.LIST_EXTORDERS
  | ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS
  | ExtOrdersActionTypes.RESET
  payload: {
    actionType?: string;
    data?: ExtOrdersType[] | ExtOrdersType | {};
    error?: string;
    id?: number;
    page: Page,
  }
}

type State = {
  extOrders?: ExtOrdersType[];
  loading?: boolean;
  error?: string
};

const findSlice = (data: ExtOrdersType[], orderId: number): number => {
  let idx = data.findIndex((a) => a.id === orderId);
  return idx < 0 ? data.length : idx;
}

const ExtOrders = (state: State = INIT_STATE, action: OrdersActionType) => {
  switch (action.type) {
    case ExtOrdersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ExtOrdersActionTypes.LIST_EXTORDERS: {
          return {
            ...state,
            extOrders: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        case ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS: {
          return {
            ...state,
            extOrders: action.payload.data,
            loading: false,
            page: action.payload.page,
          }
        }
        default:
          return { ...state };
      }
    case ExtOrdersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ExtOrdersActionTypes.LIST_EXTORDERS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        case ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
        }
        default:
          return { ...state }
      }
    case ExtOrdersActionTypes.LIST_EXTORDERS:
      return { ...state, loading: true }
    case ExtOrdersActionTypes.LIST_ENTERPRISEEXTORDERS:
      return { ...state, loading: true }
    case ExtOrdersActionTypes.RESET:
      return {
        ...state,
        ...INIT_STATE,
      }
    default:
      return { ...state };
  }
}

export default ExtOrders