import { Page } from 'redux/common';
import { FormSchemaType } from './actions'
import { FormSchemaActionTypes } from './constants'

const INIT_STATE = {
    formSchemas: [],
    loading: false,
};

type FormsSchemaActionType = {
    type:
    | FormSchemaActionTypes.API_RESPONSE_SUCCESS
    | FormSchemaActionTypes.API_RESPONSE_ERROR
    | FormSchemaActionTypes.LIST_FORMSCHEMAS
    | FormSchemaActionTypes.CREATE_FORMSCHEMA
    | FormSchemaActionTypes.UPDATE_FORMSCHEMA
    | FormSchemaActionTypes.DELETE_FORMSCHEMA
    | FormSchemaActionTypes.GET_FORMSCHEMA
    | FormSchemaActionTypes.RESET

    payload: {
        actionType?: string;
        data?: FormSchemaType[] | FormSchemaType | {};
        error?: string;
        id?: number;
        page: Page,
    }
}


type State = {
    formSchemas?: FormSchemaType[];
    loading?: boolean;
    error?: string
};

const findSlice = (data: FormSchemaType[], formId: number): number => {
    let idx = data.findIndex((a) => a.id === formId);
    return idx < 0 ? data.length : idx;
}

const FormSchemas = (state: State = INIT_STATE, action: FormsSchemaActionType) => {
    switch (action.type) {
        case FormSchemaActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FormSchemaActionTypes.LIST_FORMSCHEMAS: {
                    return {
                        ...state,
                        formSchemas: action.payload.data,
                        loading: false,
                        page: action.payload.page,
                    }
                }
                case FormSchemaActionTypes.CREATE_FORMSCHEMA: {
                    let { formSchemas = [] } = state;
                    return {
                        ...state,
                        formSchemas: [...formSchemas, action.payload.data],
                        loading: false
                    }
                }
                case FormSchemaActionTypes.UPDATE_FORMSCHEMA: {
                    let { formSchemas = [] } = state,
                        idx = findSlice(formSchemas, ((action.payload.data as FormSchemaType).id * 1)),
                        id = ((action.payload.data as FormSchemaType).id * 1),
                        formSchema = formSchemas[idx]
                    return {
                        ...state,
                        formSchemas: [...formSchemas.slice(0, idx), { ...formSchema, id }, ...formSchemas.slice(idx + 1)],
                        loading: false
                    }
                }
                case FormSchemaActionTypes.GET_FORMSCHEMA: {
                    let { formSchemas = [] } = state,
                        idx = findSlice(formSchemas, ((action.payload.data as FormSchemaType).id * 1))
                    return {
                        ...state,
                        formSchemas: [...formSchemas.slice(0, idx), action.payload.data, ...formSchemas.slice(idx + 1)],
                        loading: false
                    }
                }
                default:
                    return { ...state };
            }
        case FormSchemaActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FormSchemaActionTypes.LIST_FORMSCHEMAS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                case FormSchemaActionTypes.CREATE_FORMSCHEMA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                case FormSchemaActionTypes.UPDATE_FORMSCHEMA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                case FormSchemaActionTypes.GET_FORMSCHEMA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    }
                }
                default:
                    return { ...state }
            }
        case FormSchemaActionTypes.LIST_FORMSCHEMAS:
            return { ...state, loading: true }
        case FormSchemaActionTypes.CREATE_FORMSCHEMA:
            return { ...state, loading: true }
        case FormSchemaActionTypes.UPDATE_FORMSCHEMA:
            return { ...state, loading: true }
        case FormSchemaActionTypes.DELETE_FORMSCHEMA:
            return { ...state, loading: true }
        case FormSchemaActionTypes.GET_FORMSCHEMA:
            return { ...state, loading: true }
        case FormSchemaActionTypes.RESET:
            return {
                ...state,
                ...INIT_STATE,
            }
        default:
            return { ...state };
    }
}

export default FormSchemas