import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listService as listServicesApi,
  updateService as updateServiceApi,
  getService as getServiceApi
} from 'helpers';
import { serviceApiResponseSuccess, serviceApiResponseError, ServiceType } from './actions';
import { ServiceActionTypes } from './constants';

type ServicePayload = {
  payload: {
    id?: number,
    enterpriseId?: number,
    data: ServiceType[] | ServiceType | {},
    page?: number
    pageSize?: number
  }
  type: string
}

function* listServices({ payload: { page = 0,
  pageSize }, type }: ServicePayload): SagaIterator {
  try {
    const response = yield call(listServicesApi, {
      'page': page,
      'pageSize': pageSize,
    });
    const { services, total } = response;
    const pageCount = Math.ceil(total / 20)
    yield put(serviceApiResponseSuccess(ServiceActionTypes.LIST_SERVICES, services, { pageCount }))
  } catch (error: any) {
    yield put(serviceApiResponseError(ServiceActionTypes.LIST_SERVICES, error));
  }
}

function* updateService({ payload: { id, data }, type }: ServicePayload): SagaIterator {
  try {
    const response = yield call(updateServiceApi, id!, data);
    yield put(serviceApiResponseSuccess(ServiceActionTypes.UPDATE_SERVICE, { data, id }))
  } catch (error: any) {
    yield put(serviceApiResponseError(ServiceActionTypes.UPDATE_SERVICE, error));
  }
}

function* getService({ payload: { id }, type }: ServicePayload): SagaIterator {
  try {
    const response = yield call(getServiceApi, id!);
    yield put(serviceApiResponseSuccess(ServiceActionTypes.GET_SERVICE, response.service))
  } catch (error: any) {
    yield put(serviceApiResponseError(ServiceActionTypes.GET_SERVICE, error));
  }
}

function* watchListServices() {
  yield takeEvery(ServiceActionTypes.LIST_SERVICES, listServices);
}

function* watchUpdateService() {
  yield takeEvery(ServiceActionTypes.UPDATE_SERVICE, updateService);
}

function* watchGetService() {
  yield takeEvery(ServiceActionTypes.GET_SERVICE, getService);
}

function* serviceSaga() {
  yield all([fork(watchListServices), fork(watchUpdateService), fork(watchGetService)])
}

export default serviceSaga;