import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = "/api/v1"

function listThirdpartyApps(q?: string, page?: number, pageSize?: number,) {
    let filters = [];
    if (q) {
        filters.push(JSON.stringify({ "ID": "Q", "Value": q }))
    }
    const params = {
        'page.page': page || 0,
        'page.pageSize': pageSize || 0,
        filters
    }
    return api.get(`${baseUrl}/thirdpartyapps`, params);
}


function listEnterpriseThirdpartyApps(enterpriseId?: number, q?: string, page?: number, pageSize?: number,) {
    let filters = [];
    if (q) {
        filters.push(JSON.stringify({ "ID": "Q", "Value": q }))
    }
    const params = {
        'page.page': page || 0,
        'page.pageSize': pageSize || 0,
        filters
    }
    return api.get(`${baseUrl}/enterprise/${enterpriseId}/thirdpartyapps`, params);
}

function getAccessToken(ThirdPartyAppId: number, view_token?: number) {
    return api.get(`${baseUrl}/thirdpartyapps/${ThirdPartyAppId}`, { view_token });
}

function deleteThirdpartyapps(ThirdPartyAppId: number) {
    return api.delete(`${baseUrl}/thirdpartyapps/${ThirdPartyAppId}`);
}

export { listThirdpartyApps, listEnterpriseThirdpartyApps, getAccessToken, deleteThirdpartyapps }